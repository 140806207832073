
<script>

</script>

<main>
	<h1>Welcome to my CSS Tools</h1>

</main>

<style lang="scss">

	main {
		height: 100vh;
		width: 100vw;
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
	}

	.controls{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		&__hex{
			margin-bottom: 20px;
		}
	}
</style>